<template>
  <div class="card-success">
    <div class="card-success__ico mar-t-0">
      <img src="../../assets/img/ico/ico-cover-cart-success.svg" alt="" />
    </div>
    <h3 class="header-3 w-70">Objeto añadido a tu carrito</h3>
    <p>Sigue comprando o <a class="cursor-pointer link" @click="openCheckoutUrl">termina tu pedido</a>.</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "ProductAddedToCart",
  computed: {
    ...mapGetters ({
      cart: 'cart',
    }),
  },
  methods: {
    openCheckoutUrl() {
      if (this.cart) {
        this.trackTramitarPedido();
        if (this.myList?.id) {
          this.sendCheckout();
        }
        window.open(this.cart.checkoutUrl, "_self");
      }
    },
    trackTramitarPedido() {
      window.mixpanel.track("Tramitar comanda", {
        "Total amount": this.total,
        "Total items": this.length,
        "Cart ID": this.cart.id,
        "Role": "Propietari",
      });
    },
  }
};
</script>
