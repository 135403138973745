<template>
  <modal-dialog
      :show="show"
      @close="close"
      target-class="modal__card--mini"
  >
    <product-added-to-cart/>
  </modal-dialog>
</template>

<script>
import CanBeCanceledByEscKey from "../../Mixins/CanBeCanceledByEscKey"
import ModalDialog from "../../components/ui/ModalDialog";
import ProductAddedToCart from "./ProductAddedToCart";

export default {
  name: 'ProductAddedToCartModal',
  mixins: [CanBeCanceledByEscKey],
  components: {
    ModalDialog,
    ProductAddedToCart,
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close() {
      this.$emit('cancel')
      this.$router.back()
    },
  }
}
</script>
